@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html {
  overflow: hidden;
}

input {
  outline: none;
}

:root {
  --blue: #0445af;
  --light-blue: rgba(4, 69, 175, 0.3);
  --lighter-blue: #e5ecf7;
}

.blue {
  color: var(--blue);
}

.button__helper {
  font-size: 0.8rem;
}

.title {
  font-weight: 400;
  font-size: 1.5rem;
  position: relative;
  text-align: start;
}

.button {
  font-size: 1.4rem;
    font-weight: 700;
    color: white;
    background-color: var(--blue);
    border: none;
    border-radius: 30px;
    padding: 8px 56px;
    transition: filter 300ms ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
}

.button:hover {
  cursor: pointer;
  filter: contrast(0.8);
}

.button:active {
  filter: brightness(0.8);
}

.textarea {
  outline: none;
  border: none;
  border-bottom: 2px solid var(--light-blue);
  color: var(--blue);
  width: 100%;
  resize: none;
  overflow: hidden;
  font-size: 32px;
  line-height: 1.5;
  height: 40px !important;
  margin: 32px 0 12px 0;
}

.textarea:focus {
  color: var(--blue);
}

.input {
  font-size: 1.5rem;
  line-height: 3.5rem;
  border: none;
  border-bottom: 2px solid var(--light-blue);
  color: var(--blue);
  width: 100%;
  margin: 32px 0 12px 0;
}

.input:focus,
.textarea:focus {
  border-bottom: 2px solid var(--blue);
}

.input::placeholder,
.textarea::placeholder {
  color: var(--light-blue);
}

.input--file {
  position: relative;
  cursor: pointer;
  background-color: var(--lighter-blue);
  border: 1px dashed var(--light-blue);
  border-radius: 4px;
  width: 100%;
  height: 300px;
  margin: 32px 0 12px 0;
  transition: background-color 200ms ease;
}

.file__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.file {
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.file__info--para1 {
  font-size: 0.9rem;
}
.file__info--para2 {
  font-size: 0.8rem;
}

.upload_image--wrapper {
  position: relative;
}

.upload-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input--file:hover {
  background-color: var(--light-blue);
}

.question__number {
  color: var(--blue);
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: -36px;
}

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;
}

.error-para {
  /* display: inline-block; */
  display: inline-flex;
  background-color: #f7e6e6;
  border-radius: 4px;
  padding: 5px;
  color: #af0404;
  margin-bottom: 12px;
}

/* 

Home

*/

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.home__img--wrapper {
  max-width: 550px;
  max-height: 550px;
  user-select: none;
  display: flex;
    align-content: center;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.home__img {
  width: 80%;
  height: 80%;
  max-width:300px;
}

.button--wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 

Question

*/

.question {
  max-width: 750px;
  width: 100%;
  padding: 0 20px;
}

.textarea--helper {
  text-align: start;
  font-size: 0.75rem;
  color: var(--blue);
  margin-bottom: 12px;
}

/* 

Navigation

*/

.navigation {
  width: 30px;
  position: fixed;
  display: flex;
  bottom: 16px;
  right: 60px;
}

.nav__button {
  cursor: pointer;
  border: none;
  padding: 6px 10px;
  background-color: var(--blue);
  color: white;
  transition: background-color 200ms ease;
}

.nav__button:disabled {
  cursor: default;
  background-color: gray;
}

.nav__button--left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.nav__button--right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* 

Progress Bar

*/

.progress-bar {
  position: absolute;
  top: 0;
  background-color: var(--light-blue);
  width: 100%;
  height: 4px;
}

.progress-bar--actual-progress {
  height: 100%;
  background-color: var(--blue);
}

/* 

results

*/

.result__img {
  max-width: 300px;
  height: 100%;
  width: 100%;
}

.result__para {
  text-align: center;
}
